<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <keep-alive>
      <router-view v-if="token && $route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="token && !$route.meta.keepAlive"/>
  </div>
</template>
<script>
import {getUrlParams} from './utils/util'
export default {
  data() {
    return {
        token: localStorage.getItem("token")
    }
  },
  created() {
    let code = getUrlParams('code')

    if((this.$route.path == "/share" || this.$route.path == "/") && !code) {
      if(!this.token) {
        this.token = true
      }
      return
    }
    let token = localStorage.getItem("token")
    // let code = getUrlParams('code')
    console.log(getUrlParams('code'), window.location.search, token)
    if(!token) {
      if(!code) {
        // let url = encodeURIComponent("https://wq.luckliy.top/about" + this.$route.fullPath)
        let url = encodeURIComponent(window.location.href)
        let appId = "wx6de00e75f8b3c900"
        location.href = (`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`)
      } else {
        this.$http.post("/mpLogin", {code}).then((res) => {
          console.log(res)
          if(res.code == 200) {
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo))
            this.token = res.data.token
            // 刷新页面
          } else {
            console.log("授权失败")
          }
        })
      }
    }
  }
}
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
