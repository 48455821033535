export function getUrlParams(params) {
  // 通过 ? 分割获取后面的参数字符串
  let urlArr = location.href.split('?')
  if (urlArr.length > 1) {
    for(let urlStr of urlArr) {
      // console.log(urlStr)
      // 再通过 & 将每一个参数单独分割出来
      let paramsArr = urlStr.split('&')
      for(let i = 0,len = paramsArr.length;i < len;i++){
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split('=')
        if(arr.length > 1 && arr[0] == params) {
          return arr[1].replace("#/", "")
        } 
      }
    }
  }
  return ''
}
export function getTimer(stringTime){
  var minute = 1000 * 60
  var hour = minute * 60
  var day = hour * 24
  var week = day * 7
  var month = day * 30

  var time1 = new Date().getTime()//当前的时间戳
  var time2 = new Date(stringTime)//指定时间的时间戳
  var time = time1 - time2.getTime()

  var result = (time2.getMonth() + 1) + "-" + time2.getDate() + " " + time2.getHours() + ":" + time2.getMinutes()
  if(time < 0) {
      return result
  } else if(time/month >= 1) {
      return result
  } else if(time/week >= 1) {
      result = parseInt(time/week) + "周前"
  } else if(time/day >= 1) {
      result = parseInt(time/day) + "天前"
  } else if(time/hour >= 1) {
      result = parseInt(time/hour) + "小时前"
  } else if(time/minute >= 1) {
      result = parseInt(time/minute) + "分钟前"
  } else {
      result = "刚刚"
  }
  return result
}