import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/pt',
    name: 'paotui',
    component: () => import('../views/Paotui.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/ptadd',
    name: 'ptadd',
    component: () => import('../views/Padd.vue')
  },
  {
    path: '/pt/:id',
    name: 'ptdetail',
    component: () => import('../views/Pdetail.vue')
  },
  {
    path: '/add',
    name: 'add',
    component: () => import('../views/Add.vue')
  },
  {
    path: '/love',
    name: 'love',
    component: () => import('../views/Love.vue')
  },
  {
    path: '/sharelove',
    name: 'sharelove',
    component: () => import('../views/Sharelove.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('../views/Course.vue')
  },
  {
    path: '/daka',
    name: 'daka',
    component: () => import('../views/Daka.vue')
  },
  {
    path: '/clist',
    name: 'clist',
    component: () => import('../views/CourseList.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/Share.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/Info.vue')
  },
  {
    path: '/:id',
    name: 'detail',
    component: () => import('../views/Detail.vue')
  },
  
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
