import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Collapse,Form, ActionSheet, NumberKeyboard, PullRefresh, CollapseItem, List, Uploader, Toast, Popup, Picker, Cell, CellGroup, Switch, Field, Skeleton, Notify, Button, Tab, Tabs, Search, Sticky, Image as VanImage, Lazyload, Tag, Col, Row, Icon, Tabbar, TabbarItem } from 'vant';
import axiosapi from './utils/request'

Vue.prototype.$http = axiosapi

Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Search);
Vue.use(Sticky);
Vue.use(VanImage);
Vue.use(Lazyload);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Skeleton);
Vue.use(Notify);
Vue.use(Field);
Vue.use(Switch);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Uploader);
Vue.use(List);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(PullRefresh);
Vue.use(ActionSheet);
Vue.use(Form);
Vue.use(NumberKeyboard);
// Vue.use(ImagePreview);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
