import axios from "axios"
import { Notify } from 'vant'
 
//1. 创建axios实例,配置基础路径
const axiosapi = axios.create({
    // baseURL: 'https://wq.luckliy.top/api',
    // baseURL: 'http://localhost:8089',
    baseURL: '/api',
    timeout: 60000,//连接超时
})
 
//2. 定义请求拦截器:给所有请求都带上token
axiosapi.interceptors.request.use((req)=>{
    if(!req.headers['upload'] && req.headers['upload'] != 1) {
        req.headers['Content-type'] = 'application/json'
    }
    req.headers['Content-Language'] = 'zh_CN'
    let token = localStorage.getItem('token')//获取页面存储的token
    if (token) {
        req.headers['Authorization'] = "Bearer " + token//将token添加至请求头对象的Auth属性中
    }
    return req
},(error)=>{//返回错误的信息
    return Promise.reject(error)
})
 
//3. 定义响应拦截器: 对服务器响应客户端的数据进行统一处理
axiosapi.interceptors.response.use(res=>{
    //3.1 对响应数据进行处理
    let data = res.data
    let code = data.code
    if(code === 401){
        //请求成功时,将res.data对象返回给响应对象
        // return data
        localStorage.removeItem("token")
        let url = encodeURIComponent(window.location.href)
        let appId = "wx6de00e75f8b3c900"
        location.href = (`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`)
    } else if (code !== 200) {
        Notify({ type: 'warning', message: data.msg });
    }
    return data
    
},(error)=>{
    return Promise.reject(error)
})
 
//4. 导出axios实例,供组件异步请求使用
export default axiosapi